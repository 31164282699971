import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getInscriptionsFromTx } from '@/api/getInscriptionsFromTx';
import { OrdinalsCard } from '@/components/OrdinalsCard';
import { SnipeForm } from '@/components/SnipeForm';
import debounce from 'lodash.debounce';
import { Network, useOrdConnect } from '@ordzaar/ord-connect';

enum PAGE_MODE {
  FREE = 'free',
  TXID = 'txid',
  INSCRIPTION = 'inscriptionId',
}

function detectSnipeableType(snipeableAddress: string | undefined): PAGE_MODE {
  if (!snipeableAddress) {
    return PAGE_MODE.FREE;
  }
  if (snipeableAddress.length === 64) {
    return PAGE_MODE.TXID;
  }
  return PAGE_MODE.INSCRIPTION;
}

export const SnipePage = ({ testnet = false }: { testnet?: boolean }) => {
  const { snipeableAddress } = useParams();
  const { network, updateNetwork } = useOrdConnect();
  const [pageMode] = useState<PAGE_MODE>(detectSnipeableType(snipeableAddress));
  const [tx, setTx] = useState<string | undefined>(snipeableAddress);
  const [inscriptions, setInscriptions] = useState<
    Awaited<ReturnType<typeof getInscriptionsFromTx>>
  >([]);
  const [selectedInscriptions, setSelectedInscriptions] = useState<string[]>(
    []
  );

  useEffect(() => {
    if (testnet && network !== Network.TESTNET) {
      updateNetwork(Network.TESTNET);
    }
  }, [testnet]);

  useEffect(() => {
    if (pageMode === PAGE_MODE.TXID) {
      getInscriptionsFromTx(tx!, testnet).then(inscriptions => {
        if (inscriptions.length === 1) {
          setSelectedInscriptions(inscriptions[0]!.id);
        }
        setInscriptions(inscriptions);
      });
    }
  }, [pageMode, tx]);

  const debouncedSetTx = useCallback(
    debounce(value => {
      setTx(value);
    }, 500),
    []
  );

  return (
    <div className="flex flex-col w-full items-center justify-center">
      <section id="snipe-input" className="flex flex-col items-center w-full">
        <div className="form-control w-full flex items-center min-w-[400px]">
          <label className="label">
            <span className="label-text">Snipe Address</span>
          </label>
          <input
            type="text"
            placeholder="Enter transaction ID or inscription ID"
            defaultValue={tx}
            onChange={e => {
              debouncedSetTx(e.target.value);
            }}
            className="input input-bordered bg-secondary w-[650px]"
          />
        </div>
      </section>
      <section id="inscriptions" className="flex flex-col items-center">
        {inscriptions.length > 0 ? (
          <>
            <h2 className="pb-3">Select Inscriptions to snipe</h2>
            {inscriptions.map(inscription => (
              <OrdinalsCard
                key={inscription?.id}
                selected={selectedInscriptions?.includes(inscription?.id)}
                toggleCheckbox={() => {
                  if (selectedInscriptions.includes(inscription?.id)) {
                    setSelectedInscriptions(
                      selectedInscriptions.filter(
                        selectedInscription =>
                          selectedInscription !== inscription?.id
                      )
                    );
                    return;
                  } else {
                    setSelectedInscriptions(prev => [...prev, inscription?.id]);
                  }
                }}
                inscription={inscription!}
              />
            ))}
          </>
        ) : (
          <p>No inscriptions found</p>
        )}
        {inscriptions.length > 0 ? (
          <SnipeForm
            tx={inscriptions[0]!.tx}
            inscriptions={inscriptions.filter(inscription =>
              selectedInscriptions.includes(inscription?.id)
            )}
          />
        ) : null}
      </section>
    </div>
  );
};
