import { useState } from 'react';
import { toast } from 'react-toastify';
import { snipeTransaction } from '@/utils/snipeTx';
import { Network, useOrdConnect, useSign } from '@ordzaar/ord-connect';
import { MempoolTransaction } from '@/types/transactionMempoolResponse.type';

function getDesiredVinsFromTx(
  tx: MempoolTransaction,
  inscriptions: { script_pubkey: string }[]
) {
  const inscriptionPubkeys = inscriptions.map(
    inscription => inscription.script_pubkey
  );
  return tx.vin.filter(input =>
    inscriptionPubkeys.includes(input.prevout.scriptpubkey_asm)
  );
}

export const SnipeForm = ({
  tx,
  inscriptions,
}: {
  tx: MempoolTransaction;
  inscriptions: any[];
}) => {
  const { sign } = useSign();
  const { address } = useOrdConnect();
  const [feeRate, setFeeRate] = useState<number>(tx.fee);
  const { network } = useOrdConnect();
  const isTestnet = network === Network.TESTNET;

  const handleSnipeTransaction = async () => {
    if (Number(feeRate) < tx.fee) {
      toast.error(
        `Fee rate cannot be lower than the minimum fee rate of ${tx.fee} sat/byte.`
      );
      return;
    }

    try {
      if (!tx.txid) return;
      const desiredInscriptions = getDesiredVinsFromTx(tx, inscriptions);
      const inputTxids = tx.vin
        .map(input => {
          if (desiredInscriptions.includes(input)) {
            return input.txid;
          }
          return undefined;
        })
        .filter(Boolean) as string[];
      const result = await snipeTransaction({
        txid: tx.txid,
        sign: psbtBase64 => sign(address.ordinals!, psbtBase64, {}),
        desiredFee: feeRate,
        buyerPaymentAddress: address.payments!,
        buyerOrdinalAddress: address.ordinals!,
        inputTxids,
        isTestnet,
      });

      if (result) {
        toast.success('Transaction sniped successfully!');
      }
    } catch (error) {
      console.error('Error sniping transaction:', error);
      toast.error('Error sniping transaction.');
    }
  };

  return (
    <div className="flex flex-col justify-center items-center mt-8 w-full">
      <div className="max-w-md mx-auto p-4 bg-white shadow-md rounded-md w-full">
        <div className="mb-4">
          <label className="block text-gray-700">Fee Rate (sat/byte):</label>
          <input
            type="number"
            value={feeRate}
            onChange={event => setFeeRate(Number(event.target.value))}
            className="w-full px-3 py-2 border text-gray-700 rounded-md"
          />
          {tx.fee > 0 && (
            <p
              className="text-sm text-gray-600 cursor-pointer"
              onClick={() => setFeeRate(tx.fee + 110)}
            >
              Minimum fee rate:{' '}
              <span className="text-blue-500">{tx.fee + 110}</span> sat/byte
            </p>
          )}
        </div>
        <button
          onClick={handleSnipeTransaction}
          className="w-full px-4 py-2 bg-green-500 text-white rounded-md"
        >
          Snipe Transaction
        </button>
      </div>
    </div>
  );
};
