export const FEE_ADDRESS = 'bc1qar0srrr7xfkvy5l643lydnw9re59gtzzwf5mdq';
export const FEE_ADDRESS_TESTNET =
  'tb1pp40tcyxcu20v78t2zjjl8smh8dtzfnzprzywch92kzqh236avtds9jau0v';
export const SERVICE_FEE = 0.03; // 3%
export const MODE = import.meta.env.MODE;
export const VITE_API_URL =
  import.meta.env.VITE_API_URL || 'http://localhost:3000';
export const SAT_PER_BTC = 100_000_000;
export const BTC_PER_SAT = 1e-8;
export const ORD_SERVER_RPC_TESTNET_URL =
  'https://testnet-explorer.ordinalsbot.com';
export const ORD_SERVER_RPC_MAINNET_URL = 'https://with19.ordstuff.info';
