import { getInscriptionsFromTx } from '@/api/getInscriptionsFromTx';

type Props = {
  inscription: NonNullable<
    Awaited<ReturnType<typeof getInscriptionsFromTx>>[0]
  >;
  selected: boolean;
  toggleCheckbox: () => void;
};

export const OrdinalsCard = ({
  inscription,
  selected,
  toggleCheckbox,
}: Props) => {
  return (
    <div className="card shadow-xl w-fit rounded-md relative">
      <input
        type="checkbox"
        onChange={toggleCheckbox}
        checked={selected}
        className="absolute top-2 right-2 checkbox checkbox-lg checkbox-secondary"
      />
      <figure>
        <img src="" alt={inscription.name} width={150} height="auto" />
      </figure>
      <div className="card-body bg-secondary">
        <h2 className="card-title">{inscription.name}</h2>
        <p>ID: {inscription.id}</p>
        {inscription.attributes &&
          Object.keys(inscription.attributes).length > 0 && (
            <>
              <h3 className="mt-5">Metadata</h3>
              <div className="flex justify-between flex-wrap">
                {Object.entries(inscription.attributes).map(([key, value]) => (
                  <p className="w-[50%] py-2" key={key}>
                    {key}: {value}
                  </p>
                ))}
              </div>
            </>
          )}
      </div>
    </div>
  );
};
