import axios, { AxiosResponse } from 'axios';
import { MempoolTransaction } from '@/types/transactionMempoolResponse.type';
import cbor from 'cbor';
import {
  ORD_SERVER_RPC_MAINNET_URL,
  ORD_SERVER_RPC_TESTNET_URL,
} from '@/app.conf';

export function getTxInfo(
  txId: string,
  isTestnet: boolean
): Promise<AxiosResponse<MempoolTransaction>> {
  if (!txId) {
    throw new Error('No txId provided');
  }
  return axios.get(
    `https://mempool.space/${isTestnet ? 'testnet/' : ''}api/tx/${txId}`,
    {
      headers: {
        Accept: 'application/json',
      },
    }
  );
}

export interface TransactionOrd {
  chain: string;
  etching: null;
  inscription_count: number;
  transaction: Transaction;
  txid: string;
}

export interface Transaction {
  version: number;
  lock_time: number;
  input: Input[];
  output: Output[];
}

export interface Input {
  previous_output: string;
  script_sig: string;
  sequence: number;
  witness: string[];
}

export interface Output {
  value: number;
  script_pubkey: string;
}

export async function getInscriptionsFromTx(txId: string, isTestnet = false) {
  try {
    const { data: txInfo } = await getTxInfo(txId, isTestnet);

    const outputs = await Promise.all(
      txInfo.vin.map(
        async input =>
          await axios
            .get(
              `${isTestnet ? ORD_SERVER_RPC_TESTNET_URL : ORD_SERVER_RPC_MAINNET_URL}/output/${input.txid}:${input.vout}`,
              { headers: { Accept: 'application/json' } }
            )
            .then(({ data }) => data)
      )
    );

    const inscriptions = outputs
      .filter(output => output.inscriptions.length)
      .map(output => ({
        inscriptions: output.inscriptions,
        script_pubkey: output.script_pubkey,
      }));

    const inscriptionsWithMetadata = await Promise.allSettled(
      inscriptions.map(async inscription => {
        try {
          const metadata = await getInscriptionMetadata(
            inscription.inscriptions[0],
            isTestnet
          );
          return {
            ...metadata,
            id: inscription.inscriptions[0],
            tx: txInfo,
            script_pubkey: inscription.script_pubkey as string,
          };
        } catch (error) {
          console.error('Error fetching inscription metadata:', error);
          return {
            id: inscription.inscriptions[0],
            tx: txInfo,
            script_pubkey: inscription.script_pubkey as string,
          };
        }
      })
    );

    const processedInscriptions = inscriptionsWithMetadata
      .map(result => {
        if (result.status === 'fulfilled') {
          return result.value;
        } else {
          return undefined;
        }
      })
      .filter(Boolean);

    return processedInscriptions;
  } catch (error) {
    console.error('Error fetching inscriptions:', error);
    if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error('Error fetching inscriptions.');
  }
}

export interface OutputResponse {
  address: string;
  indexed: boolean;
  inscriptions: string[];
  runes: Runes;
  sat_ranges: null;
  script_pubkey: string;
  spent: boolean;
  transaction: string;
  value: number;
}

export interface Runes {
  id: string;
}

export interface Inscription {
  address: string;
  charms: any[];
  children: any[];
  content_length: number;
  content_type: string;
  effective_content_type: string;
  fee: number;
  height: number;
  id: string;
  next: string;
  number: number;
  parents: any[];
  previous: string;
  rune: null;
  sat: null;
  satpoint: string;
  timestamp: number;
  value: number;
}

const getInscriptionParents = async (
  inscriptionId: string,
  isTestnet: boolean
) => {
  try {
    const { data } = await axios.get(
      `https://${isTestnet ? ORD_SERVER_RPC_TESTNET_URL : ORD_SERVER_RPC_MAINNET_URL}/r/parents/${inscriptionId}`,
      {
        headers: {
          Accept: 'application/json',
        },
      }
    );
    return data;
  } catch (error) {
    console.error('Error fetching inscription parents:', error);
    if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error('Error fetching inscription parents.');
  }
};

function getImgUrlFromResponse(response: string, isTestnet: boolean) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(response, 'text/html');
  console.log(doc);
  const imageElement = doc.querySelector('img');

  if (imageElement) {
    const imageHref = imageElement.getAttribute('src');
    return `https://${isTestnet ? ORD_SERVER_RPC_TESTNET_URL : ORD_SERVER_RPC_MAINNET_URL}/${imageHref}`;
  } else {
    console.log('Image element not found');
  }
}

export const getInscriptionImage = async (
  inscriptionId: string,
  isTestnet: boolean
): Promise<string | undefined> => {
  try {
    const { data } = await axios.get(
      `https://${isTestnet ? ORD_SERVER_RPC_TESTNET_URL : ORD_SERVER_RPC_MAINNET_URL}/preview/${inscriptionId}`,
      {
        headers: {
          Accept: 'application/json',
        },
      }
    );
    return getImgUrlFromResponse(data, isTestnet);
  } catch (error) {
    console.error('Error fetching inscription image:', error);
    if (error instanceof Error) {
      throw new Error(error.message);
    }
    throw new Error('Error fetching inscription image.');
  }
};

export const getInscriptionMetadata = async (
  inscriptionId: string,
  isTestnet: boolean
): Promise<
  | {
      attributes: Record<string, any>;
      name: string;
      image: string;
    }
  | undefined
> => {
  try {
    const { data } = await axios.get(
      `https://${isTestnet ? ORD_SERVER_RPC_TESTNET_URL : ORD_SERVER_RPC_MAINNET_URL}/r/metadata/${inscriptionId}`,
      {
        headers: {
          Accept: 'application/json',
        },
      }
    );
    if (data) {
      const image = await getInscriptionImage(inscriptionId, isTestnet);
      return { ...cbor.decode(data), image };
    }
    return;
  } catch (error) {
    console.error('Error fetching inscription metadata:', error);
    if (error instanceof Error) {
      try {
        const parents = await getInscriptionParents(inscriptionId, isTestnet);
        if (!parents.length) {
          throw new Error(`No parents found for ${inscriptionId}`);
        }
        const metadata = await getInscriptionMetadata(parents[0], isTestnet);
        return metadata;
      } catch (error) {
        console.error('Error fetching inscription parents:', error);
        if (error instanceof Error) {
          throw new Error(error.message);
        }
      }
    }
    throw new Error('Error fetching inscription metadata.');
  }
};

// export const getInscriptionMetadata = async (
//   inscriptionId: string
// ): Promise<InscriptionMagicEden> => {
//   try {
//     const { data } = await axios.get(
//       `${VITE_API_URL}/api/snipe/${inscriptionId}`,
//       {
//         headers: {
//           Accept: 'application/json',
//         },
//       }
//     );
//     return data;
//   } catch (error) {
//     console.error('Error fetching inscription metadata:', error);
//     if (error instanceof Error) {
//       throw new Error(error.message);
//     }
//     throw new Error('Error fetching inscription metadata.');
//   }
// };

interface Collection {
  symbol: string;
  name: string;
  imageURI: string;
  chain: string;
  inscriptionIcon: string;
  description: string;
  supply: number;
  twitterLink: string;
  discordLink: string;
  websiteLink: string;
  createdAt: string;
  overrideContentType: string;
  disableRichThumbnailGeneration: boolean;
  labels: any[];
  creatorTipsAddress: string;
  enableCollectionOffer: boolean;
}

interface Reinscription {
  id: string;
  contentURI: string;
  contentType: string;
  displayName: string;
}

export interface InscriptionMagicEden {
  id: string;
  contentURI: string;
  contentType: string;
  contentBody: string;
  contentPreviewURI: string;
  genesisTransaction: string;
  genesisTransactionBlockTime: string;
  genesisTransactionBlockHash: string;
  genesisTransactionBlockHeight: number;
  inscriptionNumber: number;
  chain: string;
  meta: Record<string, any>;
  location: string;
  locationBlockHeight: number;
  locationBlockTime: string;
  locationBlockHash: string;
  output: string;
  outputValue: number;
  owner: string;
  listed: boolean;
  listedPrice: number;
  listedForMint: boolean;
  collectionSymbol: string;
  collection: Collection;
  itemType: string;
  sat: number;
  satName: string;
  satRarity: string;
  satBlockHeight: number;
  satBlockTime: string;
  satributes: string[];
  displayName: string;
  reinscriptions: Reinscription[];
  lastSalePrice: number;
  updatedAt: string;
}
