import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App.tsx';
import './index.css';
import { Network, OrdConnectProvider } from '@ordzaar/ord-connect';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

ReactDOM.createRoot(document.getElementById('satoshi')!).render(
  <React.StrictMode>
    <OrdConnectProvider initialNetwork={Network.MAINNET}>
      <App />
      <ToastContainer />
    </OrdConnectProvider>
  </React.StrictMode>
);
