import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { OrdConnectKit } from '@ordzaar/ord-connect';
import Logo from './assets/logo.png';
import { SnipePage } from './pages/SnipePage';
import './index.css';

const NotFound = () => (
  <div className="flex justify-center">
    <h1 className="text-2xl font-bold">404 - Page Not Found</h1>
  </div>
);

export function App() {
  return (
    <Router>
      <>
        <header className="flex bg-secondary justify-between items-center py-2 px-2 w-full">
          <div className="flex items-center">
            <Link to="/">
              <img src={Logo} width={70} alt="Logo" />
            </Link>
            <div className="flex flex-col pl-2 justify-between">
              <p className="text-[18px] font-bold">MJOLNIR</p>
              <p className="text-[10px]">WE RULE THE MEMPOOL</p>
            </div>
          </div>
          <OrdConnectKit />
        </header>
        <main className="flex justify-center">
          <Routes>
            <Route
              index
              path="testnet/snipe/:snipeableAddress"
              element={<SnipePage testnet />}
            />
            <Route
              index
              path="snipe/:snipeableAddress"
              element={<SnipePage />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <footer></footer>
      </>
    </Router>
  );
}

export default App;
